import type { PropsWithChildren } from 'react';
import { Toaster } from 'sonner';
import MainFooter from './main-footer';
import MainHeader from './main-header';

export default function MainLayout({ children }: PropsWithChildren) {
  return (
    <>
      <MainHeader />
      <Toaster />
      {children}
      {/* <OrderButton /> */}
      <MainFooter />
    </>
  );
}
